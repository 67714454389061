// Spark Pointer
// =============
//
// An abstraction for pointer, mouse, and touch events.
//
// * Author: Nurun

'use strict';

module.exports = require('./src/draggable');
