'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');
var DeviceEnum = window.SparkDeviceEnum || /* istanbul ignore next */ require('spark-device-enum');
var SparkHelpers = window.SparkHelpers || /* istanbul ignore next */ require('spark-helpers');
var SparkPointer = window.SparkPointer || /* istanbul ignore next */ require('spark-pointer');

var Coordinate = SparkHelpers.coordinate;
var Utilities = SparkHelpers.utilities;
var MathHelpers = SparkHelpers.math;
var StyleHelpers = SparkHelpers.style;
var Device = SparkHelpers.device;

var Draggable = function(el, opts) {
  /**
   * The draggable element.
   * @type {Element}
   * @private
   */
  this.element = el;
  this.$el = $(el);

  /**
   * Override any defaults with the given options.
   * @type {Object}
   */
  this.options = $.extend({}, Draggable.settings.defaults, opts);

  /**
   * The element which contains the target.
   * @type {Element}
   * @private
   */
  this.containerEl_ = el.parentNode;

  /**
   * Current position of the handle/target.
   * @type {Coordinate}
   * @private
   */
  this.currentPosition_ = new Coordinate();

  /**
   * Starting location of the drag.
   * @type {Coordinate}
   * @private
   */
  this.relativeZero_ = new Coordinate();

  /**
   * Velocity at which the draggable was thrown. This value decays over time
   * after a throw.
   * @private
   * @type {Coordinate}
   */
  this.throwVelocity_ = new Coordinate();

  /**
   * The change in position from the start of the drag.
   * @private
   * @type {Coordinate}
   */
  this.delta_ = new Coordinate();

  /**
   * Animation frame id.
   * @private
   * @type {number}
   */
  this.requestId_ = 0;

  /**
   * Limits of how far the draggable element can be dragged.
   * @type {MathHelpers.Rect}
   */
  this.limits = new MathHelpers.Rect(NaN, NaN, NaN, NaN);

  this.pointer = new SparkPointer({
    element: this.element,
    axis: this.options.axis,
    preventEventDefault: true
  });

  this.$el.addClass('grabbable');

  // Kick off.
  this.bindEvents_();
};

Draggable.settings = require('./settings');

/**
 * Throws an error if `condition` is falsy.
 * @param {boolean} condition The condition to test.
 * @param {string} message Error message.
 * @throws {Error} If condition is falsy.
 * @private
 */

function assertOk(condition, message) {
  if (!condition) {
    throw new Error(message);
  }
}

Draggable.prototype.bindEvents_ = function() {

  var pointer = this.pointer;

  this.$el.on(SparkPointer.settings.EventType.START, this.handleDragStart_.bind(this));
  this.$el.on(SparkPointer.settings.EventType.MOVE, this.handleDragMove_.bind(this));
  this.$el.on(SparkPointer.settings.EventType.END, this.handleDragEnd_.bind(this));
  this.$el.on('draggable:setEnabled', function(e, enabled) {
    pointer.setEnabled(enabled);
  });
};

/**
 * Ensure the containing element has a width and height.
 * @private
 */
Draggable.prototype.assertSizeOk_ = function() {
  assertOk(this.containmentWidth_ > 0, 'containing element\'s width is zero');
  assertOk(this.containmentHeight_ > 0, 'containing element\'s height is zero');
};

/**
 * Saves the containment element's width and height and scrubber position.
 * @private
 */
Draggable.prototype.setDimensions_ = function() {
  var relativeElement = DeviceEnum.CAN_TRANSITION_TRANSFORMS ?
    this.element :
    this.containerEl_;

  this.containmentWidth_ = relativeElement.offsetWidth;
  this.containmentHeight_ = relativeElement.offsetHeight;

  this.assertSizeOk_();

  this.relativeZero_ = this.getRelativeZero_();
};

/**
 * The position relative to the rest of the page. When it's first
 * initialized, it is zero zero, but after dragging, it is the position
 * relative to zero zero.
 * @return {!Coordinate}
 * @private
 */
Draggable.prototype.getRelativeZero_ = function() {
  return Coordinate.difference(
    this.getDraggablePosition_(),
    this.getOffsetCorrection_());
};

Draggable.prototype.getDraggablePosition_ = function() {
  var elRect = this.element.getBoundingClientRect();
  return new Coordinate(elRect.left, elRect.top);
};

/**
 * Because the draggable element gets moved around and repositioned,
 * the bounding client rect method and the offset left and top properties
 * are unreliable once the element has been dragged once. This method uses
 * the bounding client rect of the parent element to get a "correction"
 * value.
 * @return {!Coordinate}
 * @private
 */
Draggable.prototype.getOffsetCorrection_ = function() {
  // getBoundingClientRect does not include margins. They must be accounted for.
  var containmentRect = this.containerEl_.getBoundingClientRect();
  var paddings = StyleHelpers.getPaddingBox(this.containerEl_);
  var margins = StyleHelpers.getMarginBox(this.element);
  var offsetCorrectionX = margins.left + paddings.left + containmentRect.left;
  var offsetCorrectionY = margins.top + paddings.top + containmentRect.top;
  return new Coordinate(offsetCorrectionX, offsetCorrectionY);
};

/**
 * Sets the current position coordinate to a new coordinate.
 * @param {Coordinate} position Where the x and y values are a percentage.
 *     e.g. 50 for "50%".
 */
Draggable.prototype.setCurrentPosition_ = function(position) {
  this.pointer.applyFriction(position);
  var x = this.limitX((position.x / 100) * this.containerEl_.offsetWidth);
  var y = this.limitY((position.y / 100) * this.containerEl_.offsetHeight);
  this.currentPosition_ = this.getOutputCoordinate_(Math.round(x), Math.round(y));
};

/**
 * Returns the draggable element.
 * @return {Element}
 */
Draggable.prototype.getElement = function() {
  return this.pointer.getElement();
};

/**
 * Sets (or reset) the Drag limits after a Dragger is created.
 * @param {MathHelpers.Rect} limits Object containing left, top, width,
 *     height for new Dragger limits.
 */
Draggable.prototype.setLimits = function(limits) {
  this.limits = limits;
};

/**
 * Clamp the x or y value.
 * @param {number} value X or Y value.
 * @param {number} rectPosition The limits starting edge. (left or top).
 * @param {number} rectSize The limits dimension. (width or height).
 * @return {number} The clamped number.
 */
Draggable.prototype.limitValue = function(value, rectPosition, rectSize) {
  var side = Utilities.defaultsTo(rectPosition, null, !isNaN(rectPosition));
  var dimension = Utilities.defaultsTo(rectSize, 0, !isNaN(rectSize));
  var max = Utilities.defaultsTo(side + dimension, Infinity, side !== null);
  var min = Utilities.defaultsTo(side, -Infinity, side !== null);
  return MathHelpers.clamp(value, min, max);
};

/**
 * Returns the 'real' x after limits are applied (allows for some
 * limits to be undefined).
 * @param {number} x X-coordinate to limit.
 * @return {number} The 'real' X-coordinate after limits are applied.
 */
Draggable.prototype.limitX = function(x) {
  return this.limitValue(x, this.limits.left, this.limits.width);
};

/**
 * Returns the 'real' y after limits are applied (allows for some
 * limits to be undefined).
 * @param {number} y Y-coordinate to limit.
 * @return {number} The 'real' Y-coordinate after limits are applied.
 */
Draggable.prototype.limitY = function(y) {
  return this.limitValue(y, this.limits.top, this.limits.height);
};

/**
 * Returns the x and y positions the draggable element should be set to.
 * @param {Coordinate=} optPosition Position to set the draggable
 *     element. This will optionally override calculating the position
 *     from a drag.
 * @return {!Coordinate} The x and y coordinates.
 * @private
 */
Draggable.prototype.getElementPosition_ = function(optPosition) {
  if (optPosition) {
    this.setCurrentPosition_(optPosition);
  }

  var newX = (this.currentPosition_.x / this.containmentWidth_) * 100;
  var newY = (this.currentPosition_.y / this.containmentHeight_) * 100;

  return this.getOutputCoordinate_(newX, newY);
};

/**
 * Ensures the y value of an x axis draggable is zero and visa versa.
 * @param {number} newX New position for the x value.
 * @param {number} newY New position for the y value.
 * @return {!Coordinate}
 * @private
 */
Draggable.prototype.getOutputCoordinate_ = function(newX, newY) {
  var outputX = 0;
  var outputY = 0;

  // Drag horizontal only.
  if (this.pointer.isXAxis()) {
    outputX = newX;

    // Drag vertical only.
  } else if (this.pointer.isYAxis()) {
    outputY = newY;

    // Drag both directions.
  } else {
    outputX = newX;
    outputY = newY;
  }

  return new Coordinate(outputX, outputY);
};

/**
 * Returns a new coordinate with limits applied to it.
 * @param {Coordinate} deltaFromStart The distance moved since the drag started.
 * @return {!Coordinate}
 * @private
 */
Draggable.prototype.getNewLimitedPosition_ = function(deltaFromStart) {
  var sum = Coordinate.sum(this.relativeZero_, deltaFromStart);
  sum.x = this.limitX(sum.x);
  sum.y = this.limitY(sum.y);
  return sum;
};

/**
 * Drag start handler.
 * @private
 */
Draggable.prototype.handleDragStart_ = function() {
  this.stopThrow_();
  this.setDimensions_();
  this.currentPosition_ = this.relativeZero_;
  this.dispatchEvent(this.makeEvent_(Draggable.settings.EventType.START));
  this.$el.addClass('grabbing');
};

/**
 * Drag move, after applyDraggableElementPosition has happened
 * @param {jQuery.Event} evt The dragger event.
 * @private
 */
Draggable.prototype.handleDragMove_ = function() {
  // Calculate the new position based on limits and the starting point.
  this.currentPosition_ = this.getNewLimitedPosition_(this.pointer.delta);

  this.dispatchEvent(this.makeEvent_(Draggable.settings.EventType.MOVE));

  if (!this.pointer.isDeactivated_) {
    this.applyDraggableElementPosition();
  }
};

/**
 * Dragging ended.
 * @private
 */
Draggable.prototype.handleDragEnd_ = function(evt) {
  this.dispatchEvent(this.makeEvent_(Draggable.settings.EventType.END));
  this.$el.removeClass('grabbing');

  if (this.options.isThrowable && this.pointer.hasVelocity(evt.currentVelocity, 0)) {
    this.throw_(evt.currentVelocity, evt.delta);
  }
};

/**
 * Start a throw based on the draggable's velocity.
 * @param {Coordinate} velocity Velocity.
 * @param {Coordinate} delta Total drag distance from start to end.
 * @private
 */
Draggable.prototype.throw_ = function(velocity, delta) {
  this.delta_ = delta;
  this.throwVelocity_ = Coordinate.scale(velocity, this.options.amplifier);
  this.animateThrow_();
};

/**
 * Scale down the velocity, update the position, and apply it. Then do it again
 * until it's below a threshold.
 * @private
 */
Draggable.prototype.animateThrow_ = function() {
  if (this.pointer.hasVelocity(this.throwVelocity_, this.options.velocityStop)) {
    this.currentPosition_ = this.getNewLimitedPosition_(this.delta_);
    this.applyDraggableElementPosition();

    this.delta_.translate(this.throwVelocity_);
    this.throwVelocity_.scale(this.options.throwFriction);

    // Again!
    this.requestId_ = requestAnimationFrame(this.animateThrow_.bind(this));
  } else {
    // Settle on the pixel grid.
    this.currentPosition_.x = Math.round(this.currentPosition_.x);
    this.currentPosition_.y = Math.round(this.currentPosition_.y);
    this.applyDraggableElementPosition();
    this.emitSettled_();
  }
};

/**
 * Interrupt a throw.
 * @private
 */
Draggable.prototype.stopThrow_ = function() {
  this.delta_ = new Coordinate();
  this.throwVelocity_ = new Coordinate();
  cancelAnimationFrame(this.requestId_);
};

/**
 * Dispatches the SETTLE event with data. This data is different from the start,
 * move, and end events which use data from the pointer.
 * @private
 */
Draggable.prototype.emitSettled_ = function() {
  this.dispatchEvent(new SparkPointer.Event({
    type: Draggable.settings.EventType.SETTLE,
    target: this.getElement(),
    axis: this.pointer.axis,
    deltaTime: $.now() - this.pointer.startTime,
    delta: Coordinate.difference(this.relativeZero_, this.currentPosition_),
    currentVelocity: this.throwVelocity_,
    position: {
      pixel: this.getPosition(),
      percent: this.getPosition(true)
    }
  }));
};

/**
 * Make a new event with data.
 * @param {Draggable.settings.EventType} type Event type.
 * @return {!SparkPointer.Event}
 * @private
 */
Draggable.prototype.makeEvent_ = function(type) {
  return new SparkPointer.Event({
    type: type,
    target: this.getElement(),
    axis: this.pointer.axis,
    deltaTime: this.pointer.deltaTime,
    delta: Coordinate.difference(this.currentPosition_, this.relativeZero_),
    currentVelocity: this.pointer.velocity,
    position: {
      pixel: this.getPosition(),
      percent: this.getPosition(true)
    }
  });
};

/**
 * Sets the position of thd draggable element.
 * @param {Coordinate=} optPosition Position to set the draggable
 *     element. This will optionally override calculating the position
 *     from a drag.
 * @return {Coordinate} The position the draggable element was set to.
 */
Draggable.prototype.applyDraggableElementPosition = function(optPosition) {
  var pos = this.getElementPosition_(optPosition);

  // Add percentage unit
  var outputX = pos.x + '%';
  var outputY = pos.y + '%';

  if (DeviceEnum.CAN_TRANSITION_TRANSFORMS) {
    this.element.style[DeviceEnum.Dom.TRANSFORM] = Device.getTranslate(outputX, outputY);
  } else {
    this.element.style.left = outputX;
    this.element.style.top = outputY;
  }

  return this.currentPosition_;
};

/**
 * Returns the current position of the draggable element.
 * @param {boolean} optAsPercent Optionally retrieve percentage values instead
 *     of pixel values.
 * @return {Coordinate} X and Y coordinates of the draggable element.
 */
Draggable.prototype.getPosition = function(optAsPercent) {
  if (optAsPercent) {
    return new Coordinate(
      (this.currentPosition_.x / this.containerEl_.offsetWidth) * 100,
      (this.currentPosition_.y / this.containerEl_.offsetHeight) * 100);
  } else {
    return this.currentPosition_;
  }
};

/**
 * Set the position of the draggable element.
 * @param {number} x X position as a percentage. Eg. 50 for "50%".
 * @param {number} y Y position as a percentage. Eg. 50 for "50%".
 * @return {Coordinate} The position the draggable element was set to.
 */
Draggable.prototype.setPosition = function(x, y) {
  // setPosition can be called before any dragging, this would cause
  // the containment width and containment height to be undefined.
  this.update();
  return this.applyDraggableElementPosition(new Coordinate(x, y));
};

/**
 * Set the friction value.
 * @param {number} friction A number between [1, 0].
 */
Draggable.prototype.setFriction = function(friction) {
  this.pointer.setFriction(friction);
};

/**
 * Get whether dragger is enabled.
 * @return {boolean} Whether dragger is enabled.
 */
Draggable.prototype.getEnabled = function() {
  return this.pointer.getEnabled();
};

/**
 * Set whether dragger is enabled.
 * @param {boolean} enabled Whether dragger is enabled.
 */
Draggable.prototype.setEnabled = function(enabled) {
  return this.pointer.setEnabled(enabled);
};

/**
 * Easy way to trigger setting dimensions. Useful for doing things after this
 * class has been initialized, but no dragging has occurred yet.
 */
Draggable.prototype.update = function() {
  this.setDimensions_();
};

/**
 * Remove event listeners and element references.
 * @private
 */
Draggable.prototype.dispose = function() {

  this.$el.off([
    SparkPointer.settings.EventType.START,
    SparkPointer.settings.EventType.MOVE,
    SparkPointer.settings.EventType.END,
    'draggable:setEnabled'
  ].join(' '));

  this.pointer.dispose();

  this.$el.removeClass('grabbable');

  this.$el = this.containerEl_ = this.element = null;
};

Draggable.prototype.dispatchEvent = function(eventObject) {
  var newEvt = $.Event(eventObject.type, eventObject);

  // Add eventObject as the second parameter for backwards compat.
  this.$el.trigger(newEvt, eventObject);

  return newEvt.isDefaultPrevented();
};

module.exports = Draggable;
