/**
 * @fileoverview A simple class for providing a stepping function for each
 * animation frame over a given length of time. Uses requestAnimationFrame
 * where available. Its API is similar to the AnimationPlayer in `Element.animate`.
 *
 * Assumes `requestAnimationFrame` and `Function.prototype.bind` are available.
 *
 * @author glen.cheney@nurun.com (Glen Cheney)
 */

'use strict';

function noop() {}

// Underscore.js' `defaults` method. It merges object properties into the first
// parameter if the first parameter's property is undefined.
function defaults(obj) {
  for (var i = 1, length = arguments.length; i < length; i++) {
    var source = arguments[i];
    for (var prop in source) {
      if (obj[prop] === void 0) {
        obj[prop] = source[prop];
      }
    }
  }

  return obj;
}

/**
 * Easy animation stepper.
 *
 * @param {Object} options Options object.
 * @param {number} options.start Starting number. Value to animate from.
 * @param {number} options.end Ending number. Value to animate to.
 * @param {function(number, number)} options.step Step function which will receive
 *     the step value and the current percentage completed.
 * @param {number} options.duration Length of the animation. Default is 250ms.
 * @param {Object} options.context The object scope to invoke the function in.
 * @param {Function} options.easing Easing function to apply.
 * @constructor
 */
var Stepper = function(options) {

  defaults(this, options, Stepper.defaults);

  /**
   * The percentage value which the scrubber and reveals will be animated to.
   * @type {number}
   * @private
   */
  this._animationAmount = this.end - this.start;

  /**
   * Time when the animation timer started.
   * @type {number}
   * @private
   */
  this._animationStart = +new Date();

  this._handler = this._animateLoop.bind(this);

  /**
   * Called at the end of the animation with `options.context`.
   * @type {Function}
   */
  this.onfinish = noop;

  // Start loop.
  this.requestId = requestAnimationFrame(this._handler);
};

Stepper.defaults = {
  start: 0,
  end: 1,
  duration: 250,
  step: noop,
  context: window,
  easing: function(k) {
    return -0.5 * (Math.cos(Math.PI * k) - 1);
  }
};

/**
 * Internal loop ticker.
 * @private
 */
Stepper.prototype._animateLoop = function() {
  var now = new Date().getTime();
  var remainingTime = this._animationStart + this.duration - now;

  // Even when duration is zero, this will result in Infinity, which will only
  // call the step method once then onfinish, which is desired.
  var percent = 1 - (remainingTime / this.duration);

  // Abort if already at or past 100%.
  if (percent >= 1) {
    // Make sure it always finishes with 1.
    this.step.call(this.context, this.end, 1);
    this.onfinish.call(this.context);
    this.dispose();
    return;
  }

  // Apply easing.
  percent = this.easing(percent);

  // Request animation frame.
  this.requestId = requestAnimationFrame(this._handler);

  // Tick.
  this.step.call(this.context, this.start + (this._animationAmount * percent), percent);
};

/**
 * Stop the animation and dispose of it.
 */
Stepper.prototype.cancel = function() {
  cancelAnimationFrame(this.requestId);
  this.dispose();
};

/**
 * Destroy the animation instance.
 */
Stepper.prototype.dispose = function() {
  this._handler = this.context = null;
};

module.exports = Stepper;
