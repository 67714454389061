'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');

var math = require('./math');

var style = {};

var getFloat = function(value) {
  return parseFloat(value) || 0;
};

/**
 * Gets the height and with of an element when the display is not none.
 * @param {Element} element Element to get size of.
 * @return {!{width: number, height: number}} Object with width/height.
 */
style.getSize = function(element) {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight
  };
};

var getBox = function(element, property) {
  var props = window.getComputedStyle(element, null);
  return new math.Box(
    getFloat(props[property + 'Top']),
    getFloat(props[property + 'Right']),
    getFloat(props[property + 'Bottom']),
    getFloat(props[property + 'Left']));
};

style.getMarginBox = function(element) {
  return getBox(element, 'margin');
};

style.getPaddingBox = function(element) {
  return getBox(element, 'padding');
};

/**
 * Returns the size (width or height) of a list of elements, including margins.
 * @param {Array.<Element>} elements A real array of child elements.
 * @param {string} dimension Width or height.
 * @return {number}
 */
style.getElementsSize = function(elements, dimension) {
  return elements.reduce(function(memo, el) {
    var outerSize = style.getSize(el)[dimension];
    var margins = style.getMarginBox(el);
    var marginSize = dimension === 'height' ?
      margins.top + margins.bottom :
      margins.left + margins.right;

    return memo + outerSize + marginSize;
  }, 0);
};

// https://github.com/jquery/jquery/pull/764
// http://stackoverflow.com/a/15717609/373422
style.getWindowHeight = function() {
  var windowHeight = window.innerHeight;

  // Try to exclude the toolbars from the height on iPhone.
  // TODO: add isIPhone back when it's available
  // if (isIPhone) {
  //   var screenHeight = screen.height;
  //   var toolbarHeight = screenHeight - windowHeight;
  //   windowHeight = screenHeight - toolbarHeight;
  // }

  return windowHeight;
};

/**
 * Force the page to be repainted.
 */
style.forceRedraw = function() {
  var tempStyleSheet = document.createElement('style');
  document.body.appendChild(tempStyleSheet);
  document.body.removeChild(tempStyleSheet);
};

/**
 * Ask the browser for a property that will cause it to recalculate styles
 * and layout the element (and possibly surrounding/parent elements).
 * @param {Element} element Element to for a layout for.
 * @return {number} Width of the element. If you actually need the width of
 *     element, use the `style.getSize` method.
 */
style.causeLayout = function(element) {
  return element.offsetWidth;
};

style._getTallest = function(elements) {
  var tallest = 0;
  $.each(elements, function(index, element) {
    if (element.offsetHeight > tallest) {
      tallest = element.offsetHeight;
    }
  });

  return tallest;
};

/**
 * For groups of elements which should be the same height. Using this method
 * will create far less style recalculations and layouts.
 *
 * Check out {@link https://github.com/Vestride/Shuffle/blob/master/js/evenheights.js|evenheights.js}
 * for the jQuery plugin version.
 *
 * @param {ArrayLike.<Element>|ArrayLike.<ArrayLike.<Element>>} groups A jQuery
 *     collection or NodeList or an array of jQuery collections.
 * @return {number|Array.<number>} An array containing the pixel value of the
 *     tallest element for each group, or just a number if it's one group.
 */
style.evenHeights = function(groups) {
  // If the first item in the list is an element, then it needs to be wrapped
  // in an array so the rest of the methods will work.
  var isGroup = true;
  if (groups[0] && groups[0].nodeType) {
    isGroup = false;
    groups = [groups];
  }

  var winners = [];

  // First, reset the height for every element.
  // This is done first, otherwise we dirty the DOM on each loop!
  $.each(groups, function(i, elements) {
    $(elements).css('height', '');
  });

  // Now, measure heights in each group and save the tallest value. Instead of
  // setting the height value for the entire group, save it. If it were set,
  // the next iteration in the loop would have to recalculate styles in the DOM
  $.each(groups, function(i, elements) {
    winners.push(style._getTallest(elements));
  });

  // Lastly, set them all.
  $.each(groups, function(i, elements) {
    $(elements).css('height', winners[i]);
  });

  if (isGroup) {
    return winners;
  } else {
    return winners[0];
  }
};

module.exports = style;
