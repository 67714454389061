'use strict';

var string = {};

/**
 * Capitalize a string.
 * @param {string} str String to capitalize.
 * @return {string} Capitalized string.
 */
string.capitalize = function(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Hyphenates a javascript style string to a css one. For example:
 * MozBoxSizing -> -moz-box-sizing.
 *
 * @param {string|boolean} str The string to hyphenate.
 * @return {string} The hyphenated string.
 */
string.hyphenate = function(str) {

  // Catch booleans.
  if (!str) {
    return '';
  }

  // Turn MozBoxSizing into -moz-box-sizing.
  return str.replace(/([A-Z])/g, function(str, m1) {
    return '-' + m1.toLowerCase();
  }).replace(/^ms-/, '-ms-');
};

/**
 * Creates a random string for IDs, etc.
 * http://stackoverflow.com/a/8084248/995529
 *
 * @return {string} Random string.
 */
string.random = function() {
  return Math.random().toString(36).substring(7);
};

module.exports = string;
