'use strict';

var $ = window.$ || /* istanbul ignore next */ require('jquery');
var SparkHelpers = window.SparkHelpers || /* istanbul ignore next */ require('spark-helpers');

var Coordinate = SparkHelpers.coordinate;

var settings = require('./settings');

function isXAxis(axis) {
  return axis === settings.Axis.X;
}

function isYAxis(axis) {
  return axis === settings.Axis.Y;
}

function isBothAxis(axis) {
  return axis === settings.Axis.BOTH;
}

function hasDirection(direction) {
  return direction !== settings.Direction.NONE;
}

function finiteOrZero(velocity) {
  return isFinite(velocity) ? velocity : 0;
}

/**
 * Calculate the velocity between two points.
 *
 * @param {number} deltaTime Change in time.
 * @param {number} deltaX Change in x.
 * @param {number} deltaY Change in y.
 * @return {Coordinate} Velocity of the drag.
 */

function getVelocity(deltaTime, deltaX, deltaY) {
  return new Coordinate(
    finiteOrZero(deltaX / deltaTime),
    finiteOrZero(deltaY / deltaTime));
}

function getTheDirection(value1, value2, isGreater, isLess, isEqual) {
  if (value1 - value2 > 0) {
    return isGreater;
  } else if (value1 - value2 < 0) {
    return isLess;
  } else {
    return isEqual;
  }
}

/**
 * angle to direction define.
 * @param {Coordinate} coord1 The starting coordinate.
 * @param {Coordinate} coord2 The ending coordinate.
 * @return {string} Direction constant.
 */

function getDirection(coord1, coord2) {
  if (Math.abs(coord1.x - coord2.x) >= Math.abs(coord1.y - coord2.y)) {
    return getTheDirection(coord1.x, coord2.x, settings.Direction.LEFT,
      settings.Direction.RIGHT, settings.Direction.NONE);
  } else {
    return getTheDirection(coord1.y, coord2.y, settings.Direction.UP,
      settings.Direction.DOWN, settings.Direction.NONE);
  }
}

function isOnAxis(axis, direction) {
  var isXAndLeftOrRight = isXAxis(axis) && (
    direction === settings.Direction.LEFT ||
    direction === settings.Direction.RIGHT);

  var isYAndUpOrDown = isYAxis(axis) && (
    direction === settings.Direction.UP ||
    direction === settings.Direction.DOWN);

  var isBothAndNotNone = isBothAxis(axis) && hasDirection(direction);

  return isXAndLeftOrRight || isYAndUpOrDown || isBothAndNotNone;
}

function didMoveOnAxis(axis, direction, deltaX, deltaY) {
  // X axis and deltaX > 0
  return (isXAxis(axis) && Math.abs(deltaX) > 0) ||

  // Y axis and deltaY > 0
  (isYAxis(axis) && Math.abs(deltaY) > 0) ||

  // Both axis, as long as it actually moved.
  (isBothAxis(axis) && hasDirection(direction));
}

function getAxisDirection(axis, start, end) {
  start = $.extend({}, start);
  end = $.extend({}, end);

  if (isXAxis(axis)) {
    start.y = 0;
    end.y = 0;
  } else if (isYAxis(axis)) {
    start.x = 0;
    end.x = 0;
  }

  return getDirection(start, end);
}

/**
 * Object representing a drag event.
 * @param {Object} options Options object.
 * @param {string} options.type Event type.
 * @param {Element} options.target Element the event is happening on.
 * @param {Coordinate} options.delta Total movement of the pointer (with friction
 *     already applied to it).
 * @param {Coordinate} options.currentVelocity Calculated velocity since the last interval.
 * @constructor
 * @extends {jQuery.Event}
 */
var PointerEvent = function(options) {
  this.type = options.type;

  var base = new Coordinate();

  /**
   * @type {Element}
   */
  this.target = options.target;

  /**
   * Change in position since the start of the drag.
   * @type {Coordinate}
   */
  this.delta = options.delta;

  /**
   * The change in x from drag start to end.
   * @type {number}
   */
  this.deltaX = options.delta.x;

  /**
   * The change in y from drag start to end.
   * @type {number}
   */
  this.deltaY = options.delta.y;

  /**
   * Time elapsed from mouse/touch down to mouse/touch up.
   * @type {number}
   */
  this.deltaTime = options.deltaTime;

  /**
   * Velocity of the whole drag.
   * @type {Coordinate}
   */
  this.velocity = getVelocity(this.deltaTime, this.deltaX, this.deltaY);

  /**
   * The velocity in the last 100 milliseconds.
   * @type {Coordinate}
   */
  this.currentVelocity = options.currentVelocity;

  /**
   * Distance dragged.
   * @type {number}
   */
  this.distance = Coordinate.distance(base, options.delta);

  /**
   * Direction of drag.
   * @type {settings.Direction}
   */
  this.direction = getDirection(base, options.delta);

  /**
   * Whether the drag direction is on the axis of the draggable element.
   * @type {boolean}
   */
  this.isDirectionOnAxis = isOnAxis(options.axis, this.direction);

  /**
   * Whether the draggable element moved along the dragging axis at all.
   * @type {boolean}
   */
  this.didMoveOnAxis = didMoveOnAxis(options.axis, this.direction,
    this.deltaX, this.deltaY);

  /**
   * Direction of drag which excludes directions not on its axis.
   * @type {settings.Direction}
   */
  this.axisDirection = getAxisDirection(options.axis, base, options.delta);

  /** @type {{pixel: Coordinate, percent: Coordinate}} */
  this.position = options.position;
};

module.exports = PointerEvent;
