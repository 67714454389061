'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');

var utils = {};

/**
 * Fallback to a specified default if an input is undefined or null.
 * @param {*} obj The input to test.
 * @param {*} defaultValue The fallback if the input is undefined.
 * @param {boolean=} optTest If defined, `test` will be used to determine which
 *     value should be used. Tru
 * @return {*} The sanitized output, either `obj` or `defaultValue`.
 */
utils.defaultsTo = function(obj, defaultValue, optTest) {
  var test = optTest === undefined ?
    (obj === undefined || obj === null) : !optTest;
  return test ? defaultValue : obj;
};

/**
 * @param {*} obj Anything.
 * @return {boolean}
 */
utils.isString = function(obj) {
  return typeof obj === 'string';
};

/**
 * Parse a value as a number. If it's not numeric, then the default value will
 * be returned.
 * @param {*} value The option.
 * @param {*} defaultValue The fallback value.
 * @return {*} If value is numeric, value, else defaultValue.
 */
utils.getNumberOption = function(value, defaultValue) {
  value = parseFloat(value);
  return utils.defaultsTo(value, defaultValue, $.isNumeric(value));
};

/**
 * Parse a value as a string. If it's not a string, then the default value
 * will be returned.
 * @param {*} value The option.
 * @param {*} defaultValue The fallback value.
 * @return {*} If value is a string, value, else defaultValue.
 */
utils.getStringOption = function(value, defaultValue) {
  return utils.defaultsTo(value, defaultValue, utils.isString(value));
};

/**
 * Parse a value as a percentage. If it's a string with '%' in it, it will
 * be parsed as a string, otherwise it will be parsed as a number.
 * @param {*} value The option.
 * @param {*} defaultValue The fallback value.
 * @return {*}
 */
utils.getPercentageOption = function(value, defaultValue) {
  if (utils.isString(value) && value.indexOf('%') > -1) {
    return utils.getStringOption(value, defaultValue);
  } else {
    return utils.getNumberOption(value, defaultValue);
  }
};

module.exports = utils;
