// Spark Pointer __VERSION__
// =============
//
// An abstraction for pointer, mouse, and touch events.
//
// * Author: Nurun

'use strict';

module.exports = require('./src/pointer');
