// Spark Helpers __VERSION__
// ====================
//
// A collection of helper methods for Spark projects.
//
// * Author: Nurun

'use strict';

// Run polyfills
require('./src/polyfills/index');

module.exports = {
  animation: require('./src/animation'),
  array: require('./src/array'),
  browser: require('./src/browser'),
  coordinate: require('./src/coordinate'),
  device: require('./src/device'),
  dom: require('./src/dom'),
  math: require('./src/math'),
  string: require('./src/string'),
  style: require('./src/style'),
  timer: require('./src/timer'),
  utilities: require('./src/utilities'),
  enums: {
    events: require('./src/enums/events')
  }
};
