/**
 * @fileoverview An abstraction for pointer, mouse, and touch events.
 *
 * @author Glen Cheney
 */

'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');
var DeviceEnum = window.SparkDeviceEnum || /* istanbul ignore next */ require('spark-device-enum');
var SparkHelpers = window.SparkHelpers || /* istanbul ignore next */ require('spark-helpers');

var EventType = SparkHelpers.enums.events;
var Coordinate = SparkHelpers.coordinate;

var id = 0;

/**
 * An abstraction layer for adding pointer events and calculating drag values.
 * @param {Element} element Element to watch.
 * @param {string} axis Axis to use. Either 'x', 'y' or 'xy'.
 * @param {boolean} preventEventDefault Whether or not to prevent the default
 *     event during drags.
 */
var Pointer = function(options) {

  var opts = this.getOptions_(options);

  /**
   * Whether to prevent the default event action on move.
   * @type {boolean}
   * @private
   */
  this.shouldPreventDefault_ = opts.preventEventDefault;

  /**
   * The draggable element.
   * @type {Element}
   * @private
   */
  this.el_ = opts.element;

  /**
   * Starting location of the drag.
   * @type {Coordinate}
   */
  this.pageStart = new Coordinate();

  /**
   * Current position of mouse or touch relative to the document.
   * @type {Coordinate}
   */
  this.page = new Coordinate();

  /**
   * Current position of drag relative to target's parent.
   * @type {Coordinate}
   */
  this.delta = new Coordinate();

  /**
   * Used to track the current velocity. It is updated when the velocity is.
   * @type {Coordinate}
   * @private
   */
  this.lastPosition_ = new Coordinate();

  /**
   * Friction to apply to dragging. A value of zero would result in no dragging,
   * 0.5 would result in the draggable element moving half as far as the user
   * dragged, and 1 is a 1:1 ratio with user movement.
   * @type {number}
   */
  this.friction_ = 1;

  /**
   * Draggable axis.
   * @type {string}
   * @private
   */
  this.axis = opts.axis;

  /**
   * Flag indicating dragging has happened. It is set on dragmove and reset
   * after the draggableend event has been dispatched.
   * @type {boolean}
   */
  this.hasDragged = false;

  /**
   * Whether the user is locked in place within the draggable element. This
   * is set to true when `preventDefault` is called on the move event.
   * @type {boolean}
   * @private
   */
  this.isLocked_ = false;

  /**
   * Whether dragging is enabled internally. If the user attempts to scroll
   * in the opposite direction of the draggable element, this is set to true
   * and no more drag move events are counted until the user releases and
   * starts dragging again.
   * @type {boolean}
   * @private
   */
  this.isDeactivated_ = false;

  /**
   * Whether dragging is currently enabled.
   * @type {boolean}
   * @private
   */
  this.enabled_ = true;

  /**
   * A unique string to use for namespaced events with jQuery so that a tap
   * instance can be added and removed to/from the same element without affecting
   * the other instance.
   * @type {string}
   */
  this.namespace = this.getNamespace_();

  /**
   * Id from setInterval to update the velocity.
   * @type {number}
   * @private
   */
  this.velocityTrackerId_ = null;

  /**
   * Time in milliseconds when the drag started.
   * @type {number}
   */
  this.startTime = 0;

  /**
   * Length of the drag in milliseconds.
   * @type {number}
   */
  this.deltaTime = 0;

  /**
   * Used to keep track of the current velocity, it's updated with every velocity update.
   * @type {number}
   * @private
   */
  this.lastTime_ = 0;

  /**
   * The current velocity of the drag.
   * @type {Coordinate}
   */
  this.velocity = new Coordinate();

  /**
   * Whether the velocity has been tracked at least once during the drag.
   * @type {boolean}
   */
  this.hasTrackedVelocity_ = false;

  this.bind();
};

/** @type {Object} */
Pointer.settings = require('./settings');

/** @type {PointerEvent} */
Pointer.Event = require('./pointer-event');

Pointer.prototype.getOptions_ = function(options) {
  var opts = $.extend({
    element: {},
    axis: Pointer.settings.Axis.BOTH,
    preventEventDefault: true
  }, options);

  this.validateOptions_(opts);

  return opts;
};

Pointer.prototype.validateOptions_ = function(options) {
  if (!options.element.nodeType) {
    throw new TypeError('Pointer requires an element.');
  }
};

Pointer.prototype.bind = function() {
  var $el = $(this.el_);
  var start = this.handleDragStart_.bind(this);

  if (DeviceEnum.HAS_POINTER_EVENTS) {
    $el.on(EventType.POINTERDOWN + this.namespace, start);

  } else {
    $el.on(EventType.MOUSEDOWN + this.namespace, start);

    if (DeviceEnum.HAS_TOUCH_EVENTS) {
      $el.on(EventType.TOUCHSTART + this.namespace, start);
    }
  }

  // Prevent images, links, etc from being dragged around.
  // http://www.html5rocks.com/en/tutorials/dnd/basics/
  $el.on(EventType.DRAGSTART + this.namespace, false);
};

/**
 * Returns the draggable element.
 * @return {Element}
 */
Pointer.prototype.getElement = function() {
  return this.el_;
};

/**
 * Get whether dragger is enabled.
 * @return {boolean} Whether dragger is enabled.
 */
Pointer.prototype.getEnabled = function() {
  return this.enabled_;
};

/**
 * Set whether dragger is enabled.
 * @param {boolean} enabled Whether dragger is enabled.
 */
Pointer.prototype.setEnabled = function(enabled) {
  this.enabled_ = enabled;
};

/**
 * @return {boolean} Whether the draggable axis is the x direction.
 */
Pointer.prototype.isXAxis = function() {
  return this.axis === Pointer.settings.Axis.X;
};

/**
 * @return {boolean} Whether the draggable axis is the y direction.
 */
Pointer.prototype.isYAxis = function() {
  return this.axis === Pointer.settings.Axis.Y;
};

/**
 * @return {boolean} Whether the draggable axis is for both axis.
 */
Pointer.prototype.isBothAxis = function() {
  return this.axis === Pointer.settings.Axis.BOTH;
};

/**
 * Whether the event is from a touch.
 * @param {$.Event} evt Event object.
 * @return {boolean}
 */
Pointer.prototype.isTouchEvent = function(evt) {
  return !!evt.originalEvent.changedTouches;
};

/**
 * Whether the event is from a pointer.
 * @param {$.Event} evt Event object.
 * @return {boolean}
 */
Pointer.prototype.isPointerEvent = function(evt) {
  return !!evt.originalEvent.pointerId;
};

/**
 * Whether the event is from a pointer cancel or touch cancel.
 * @param {$.Event} evt Event object.
 * @return {boolean}
 * @private
 */
Pointer.prototype.isCancelEvent_ = function(evt) {
  return evt.type === EventType.POINTERCANCEL || evt.type === EventType.TOUCHCANCEL;
};

/**
 * Set the friction value.
 * @param {number} friction A number between [1, 0].
 */
Pointer.prototype.setFriction = function(friction) {
  this.friction_ = friction;
};

/**
 * Apply a friction value to a coordinate, reducing its value.
 * This modifies the coordinate given to it.
 * @param {Coordinate} coordinate The coordinate to scale.
 * @return {Coordinate} Position multiplied by friction.
 */
Pointer.prototype.applyFriction = function(coordinate) {
  return coordinate.scale(this.friction_);
};

/**
 * Retrieve the page x and page y based on an event. It normalizes
 * touch events, mouse events, and pointer events.
 * @param {$.Event} evt jQuery event.
 * @return {!Coordinate} The pageX and pageY of the press.
 * @private
 */
Pointer.prototype.getPageCoordinate_ = function(evt) {
  var pagePoints;

  // Use the first touch for the pageX and pageY.
  if (this.isTouchEvent(evt)) {
    pagePoints = evt.originalEvent.changedTouches[0];

    // Pointer events have trusted pageX and pageY values, but jQuery doesn't
    // normalize it for us because it doesn't know what pointer events are yet.
  } else if (this.isPointerEvent(evt)) {
    pagePoints = evt.originalEvent;

    // Normalized by jQuery.
  } else {
    pagePoints = evt;
  }

  return new Coordinate(pagePoints.pageX, pagePoints.pageY);
};

/**
 * If draggable is enabled and it's a left click with the mouse,
 * dragging can start.
 * @param {$.Event} evt jQuery event.
 * @return {boolean}
 * @private
 */
Pointer.prototype.canStartDrag_ = function(evt) {
  return this.getEnabled() && (this.isTouchEvent(evt) || evt.which === 1);
};

/**
 * Whether drag move should happen or exit early.
 * @return {boolean}
 * @private
 */
Pointer.prototype.canMoveDrag_ = function() {
  return this.getEnabled() && !this.isDeactivated_;
};

/**
 * Drag start handler.
 * @param  {jQuery.Event} evt The drag event object.
 * @private
 */
Pointer.prototype.handleDragStart_ = function(evt) {
  // Clear any active tracking interval.
  clearInterval(this.velocityTrackerId_);

  // Must be left click to drag.
  if (!this.canStartDrag_(evt)) {
    return;
  }

  this.setDragStartValues_(this.getPageCoordinate_(evt));

  // Give a hook to others
  var isPrevented = this.dispatchEvent(this.makeEvent_(Pointer.settings.EventType.START, evt));

  if (!isPrevented) {
    this.setupDragHandlers_(evt.type);

    // Every interval, calculate the current velocity of the drag.
    this.velocityTrackerId_ = setInterval(this.trackVelocity_.bind(this),
      Pointer.settings.VELOCITY_INTERVAL);
  }
};

/**
 * Drag move, after applyDraggableElementPosition has happened
 * @param {jQuery.Event} evt The dragger event.
 * @private
 */
Pointer.prototype.handleDragMove_ = function(evt) {
  if (!this.canMoveDrag_()) {
    return;
  }

  this.setDragMoveValues_(this.getPageCoordinate_(evt));

  var isPrevented = this.dispatchEvent(this.makeEvent_(Pointer.settings.EventType.MOVE, evt));

  // Abort if the developer prevented default on the custom event.
  if (!isPrevented && this.shouldPreventDefault_) {
    this.finishDragMove_(evt);
  }
};

/**
 * Finish the drag move function.
 * @param {jQuery.Event} evt jQuery Event.
 * @private
 */
Pointer.prototype.finishDragMove_ = function(evt) {
  // Possibly lock the user to only dragging.
  this.handleLock_();

  // Possibly stop draggable from affecting the element.
  this.handleDeactivate_();

  // Locked into dragging.
  if (this.isLocked_) {
    evt.preventDefault();
  }

  // Disregard drags and velocity.
  if (this.isDeactivated_) {
    clearInterval(this.velocityTrackerId_);
    this.velocity.x = this.velocity.y = 0;
  }
};

/**
 * Dragging ended.
 * @private
 */
Pointer.prototype.handleDragEnd_ = function(evt) {
  clearInterval(this.velocityTrackerId_);
  this.deltaTime = $.now() - this.startTime;

  // If this was a quick drag, the velocity might not have been tracked once.
  if (!this.hasTrackedVelocity_) {
    this.trackVelocity_();
  }

  // Prevent mouse events from occurring after touchend.
  this.cleanupDragHandlers();

  var endEvent = this.makeEvent_(Pointer.settings.EventType.END, evt);
  endEvent.isCancelEvent = this.isCancelEvent_(evt);

  // Emit an event.
  var isPrevented = this.dispatchEvent(endEvent);

  if (isPrevented) {
    evt.preventDefault();
  }

  this.hasDragged = false;
  this.isDeactivated_ = false;
  this.isLocked_ = false;
};

/**
 * Set the starting values for dragging.
 * @param {Coordinate} pagePosition The page position coordinate.
 * @private
 */
Pointer.prototype.setDragStartValues_ = function(pagePosition) {
  this.pageStart = pagePosition;
  this.page = pagePosition;
  this.lastPosition_ = pagePosition;
  this.delta = new Coordinate();
  this.velocity = new Coordinate();
  this.hasTrackedVelocity_ = false;

  this.startTime = this.lastTime_ = $.now();
  this.deltaTime = 0;
};

/**
 * Set the values for dragging during a drag move.
 * @param {Coordinate} pagePosition The page position coordinate.
 * @private
 */
Pointer.prototype.setDragMoveValues_ = function(pagePosition) {
  // Get the distance since the last move.
  var lastDelta = Coordinate.difference(pagePosition, this.page);

  // Apply friction to the distance since last move.
  this.applyFriction(lastDelta);

  // Update the total delta value.
  this.delta.translate(lastDelta);

  this.page = pagePosition;
  this.deltaTime = $.now() - this.startTime;
  this.hasDragged = true;
};

/**
 * Once the user has moved past the lock threshold, keep it locked.
 * @private
 */
Pointer.prototype.handleLock_ = function() {
  if (!this.isLocked_) {
    // Prevent scrolling if the user has moved past the locking threshold.
    this.isLocked_ = this.shouldLock_();
  }
};

/**
 * Once the user has moved past the drag threshold, keep it deactivated.
 * @private
 */
Pointer.prototype.handleDeactivate_ = function() {
  if (!this.isDeactivated_) {
    // Disable dragging if the user is attempting to go the opposite direction
    // of the draggable element.
    this.isDeactivated_ = this.shouldDeactivate_();
  }
};

/**
 * @return {boolean} Whether Draggable should lock the user into draggable only.
 * @private
 */
Pointer.prototype.shouldLock_ = function() {
  var pastX = this.isXAxis() && Math.abs(this.delta.x) > Pointer.settings.LOCK_THRESHOLD;
  var pastY = this.isYAxis() && Math.abs(this.delta.y) > Pointer.settings.LOCK_THRESHOLD;
  return this.isBothAxis() || pastX || pastY;
};

/**
 * @return {boolean} Whether Draggable should stop affecting the draggable element.
 * @private
 */
Pointer.prototype.shouldDeactivate_ = function() {
  var pastX = this.isXAxis() && Math.abs(this.delta.y) > Pointer.settings.DRAG_THRESHOLD;
  var pastY = this.isYAxis() && Math.abs(this.delta.x) > Pointer.settings.DRAG_THRESHOLD;
  return !this.isLocked_ && (this.isBothAxis() || pastX || pastY);
};

/**
 * Make a new event with data.
 * @param {Pointer.settings.EventType} type Event type.
 * @return {!PointerEvent}
 * @private
 */
Pointer.prototype.makeEvent_ = function(type, evt) {
  return new Pointer.Event({
    type: type,
    target: evt.target,
    axis: this.axis,
    deltaTime: this.deltaTime,
    delta: this.delta,
    currentVelocity: this.velocity
  });
};

/**
 * The element to which the move and up events will be bound to.
 * @return {jQuery}
 * @private
 */
Pointer.prototype.getDragEventTarget_ = function() {
  return $(document);
};

/**
 * Unique namespace for this instance.
 * @return {string}
 * @private
 */
Pointer.prototype.getNamespace_ = function() {
  return '.pointer' + id++;
};

/**
 * Namespace for events added after the pointer is down.
 * @return {string}
 * @private
 */
Pointer.prototype.getTemporaryNamespace_ = function() {
  return this.namespace + '_temp';
};

/**
 * Binds events to the document for move, end, and cancel (if cancel events
 * exist for the device).
 * @param {string} startType The type of event which started the drag. It
 *     is important that the mouse events are not bound when a touch event
 *     is triggered otherwise the events could be doubled.
 * @private
 */
Pointer.prototype.setupDragHandlers_ = function(startType) {
  var $target = this.getDragEventTarget_();
  var ns = this.getTemporaryNamespace_();
  var move = this.handleDragMove_.bind(this);
  var end = this.handleDragEnd_.bind(this);

  switch (startType) {
    case EventType.POINTERDOWN:
      $target.on(EventType.POINTERMOVE + ns, move);
      $target.on(EventType.POINTERUP + ns, end);
      $target.on(EventType.POINTERCANCEL + ns, end);
      break;
    case EventType.MOUSEDOWN:
      $target.on(EventType.MOUSEMOVE + ns, move);
      $target.on(EventType.MOUSEUP + ns, end);
      break;
    case EventType.TOUCHSTART:
      $target.on(EventType.TOUCHMOVE + ns, move);
      $target.on(EventType.TOUCHEND + ns, end);
      $target.on(EventType.TOUCHCANCEL + ns, end);
      break;
  }
};

/**
 * Removes the events bound during drag start. The draggable namespace can be
 * used to remove all of them because the drag start event is still bound
 * to the actual element.
 */
Pointer.prototype.cleanupDragHandlers = function() {
  this.getDragEventTarget_().off(this.getTemporaryNamespace_());
};

/**
 * Every 100 milliseconds, calculate the current velocity with a moving average.
 * http://ariya.ofilabs.com/2013/11/javascript-kinetic-scrolling-part-2.html
 * @private
 */
Pointer.prototype.trackVelocity_ = function() {
  var now = $.now();
  var elapsed = now - this.lastTime_;
  var delta = Coordinate.difference(this.page, this.lastPosition_);
  this.applyFriction(delta);
  this.lastTime_ = now;
  this.lastPosition_ = this.page;

  // velocity = delta / time.
  // Clamp the velocity to avoid outliers.
  this.velocity.x = SparkHelpers.math.clamp(delta.x / elapsed, -Pointer.settings.MAX_VELOCITY, Pointer.settings.MAX_VELOCITY);
  this.velocity.y = SparkHelpers.math.clamp(delta.y / elapsed, -Pointer.settings.MAX_VELOCITY, Pointer.settings.MAX_VELOCITY);

  this.hasTrackedVelocity_ = true;
};

/**
 * Determine whether the draggable event has enough velocity to be
 * considered a swipe.
 * @param {Object} velocity Object with x and y properties for velocity.
 * @param {number} [threshold] Threshold to check against. Defaults to the swipe
 *     velocity constant. Must be zero or a positive number.
 * @return {boolean}
 */
Pointer.prototype.hasVelocity = function(velocity, threshold) {
  threshold = SparkHelpers.utilities.defaultsTo(threshold, Pointer.settings.SWIPE_VELOCITY);

  if (this.isYAxis()) {
    return Math.abs(velocity.y) > threshold;

  } else if (this.isXAxis()) {
    return Math.abs(velocity.x) > threshold;

  } else {
    // Otherwise check both axis for velocity.
    return Math.abs(velocity.x) > threshold || Math.abs(velocity.y) > threshold;
  }
};

Pointer.prototype.dispatchEvent = function(eventObject) {
  var newEvt = $.Event(eventObject.type, eventObject);

  $(this.el_).trigger(newEvt);

  return newEvt.isDefaultPrevented();
};

Pointer.prototype.dispose = function() {
  clearInterval(this.velocityTrackerId_);
  this.cleanupDragHandlers();

  // Remove pointer/mouse/touch events by namespace.
  $(this.el_).off(this.namespace);

  this.el_ = null;
};

module.exports = Pointer;
