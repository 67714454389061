'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');

var dom = {};

/**
 * Retrieves the first child which is an element from a parent node.
 * @param {Element} node Parent node.
 * @return {?Element} The first element child or null if there isn't one.
 */
dom.getFirstElementChild = function(node) {
  if (node.firstElementChild) {
    return node.firstElementChild;
  }

  return $(node).children()[0] || null;
};

/**
 * Removes all children from a parent node.
 * @param {Element} element Parent node.
 */
dom.removeChildren = function(element) {
  $(element).empty();
};

/**
 * Retrieves all children (excluding text nodes) for an element. `children` is
 * available in IE9+, but does not work for document fragments nor SVG.
 * @param {Element} element Parent node.
 * @return {!Array.<Element>} A real array of child elements.
 */
dom.getChildren = function(element) {
  return $(element).children().get();
};

/**
 * Swaps element1 with element2 in the DOM.
 * @param {Element} elm1 first element.
 * @param {Element} elm2 second element.
 */
dom.swapElements = function(elm1, elm2) {
  if (!elm1 || !elm2) {
    return;
  }

  var parent1 = elm1.parentNode;
  var next1 = elm1.nextSibling;
  var parent2 = elm2.parentNode;
  var next2 = elm2.nextSibling;

  parent1.insertBefore(elm2, next1);
  parent2.insertBefore(elm1, next2);
};

/**
 * Ripped from: goog.testing.editor.dom.getRelativeDepth_.
 *
 * Returns the depth of the given node relative to the given parent node, or -1
 * if the given node is not a descendant of the given parent node. E.g. if
 * node == parentNode returns 0, if node.parentNode == parentNode returns 1,
 * etc.
 * @param {Node} node Node whose depth to get.
 * @param {Node} parentNode Node relative to which the depth should be
 *     calculated.
 * @return {number} The depth of the given node relative to the given parent
 *     node, or -1 if the given node is not a descendant of the given parent
 *     node.
 */
dom.getRelativeDepth = function(node, parentNode) {
  var depth = 0;
  while (node) {
    if (node === parentNode) {
      return depth;
    }

    node = node.parentNode;
    depth++;
  }

  return -1;
};

dom.getPreviousElementSibling = function(node) {
  return $(node).prev()[0] || null;
};

dom.getNextElementSibling = function(node) {
  return $(node).next()[0] || null;
};

/**
 * Retrieves the nth sibling of an element, or null if the would be nth sibling
 * does not exist. Heads up! This function excludes text nodes.
 * @param {Element} node Element to start looking from.
 * @param {number} n An integer representing the desired element relative to
 *     `node`. For example, `2` would look for `node.nextSibling.nextSibling`.
 * @param {boolean=} optIsForward Whether to look forwards or backwards. Default is true.
 * @return {?Element} The nth sibling or null.
 */
dom.getNthSibling = function(node, n, optIsForward) {
  var isForward = optIsForward !== false;
  var siblingCount = 0;
  do {
    node = isForward ?
      dom.getNextElementSibling(node) :
      dom.getPreviousElementSibling(node);
    siblingCount++;
  } while (node && siblingCount < n);
  return node;
};

module.exports = dom;
