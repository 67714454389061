'use strict';

/**
 * A simple timer class. The timer does not start automatically when
 * initialized.
 * @param {Function} fn Callback for when the timer expires.
 * @param {number} delay Timer length in milliseconds.
 * @param {boolean=} opt_continuous If true, the timer will automatically
 *     restart itself when it expires.
 * @constructor
 */
var Timer = function(fn, delay, continuous) {
  this.timerId = null;
  this.startTime = null;
  this.isPaused = false;
  this.isTicking = false;
  this.isContinuous = continuous;
  this.delay = delay;
  this.remaining = delay;
  this.fn = fn;
};

/**
 * Starts ticking the timer.
 * @return {number|boolean} The remaining time or false if the timer is
 *     already ticking.
 */
Timer.prototype.resume = Timer.prototype.start = function() {
  if (this.isTicking) {
    return false;
  }

  this.startTime = Date.now();
  var _this = this;
  this.timerId = setTimeout(function() {
    _this.fn();

    // If the timer wasn't stopped in the callback and this is a continuous
    // timer, start it again.
    if (!_this.isPaused && _this.isContinuous) {
      _this.restart();
    } else {
      _this.reset();
    }
  }, this.remaining);
  this.isTicking = true;
  this.isPaused = false;
  return this.remaining;
};

/**
 * Pauses the timer. Resuming will continue it with the remaining time.
 * @return {number} Time remaining.
 */
Timer.prototype.pause = Timer.prototype.stop = function() {
  this.clear();
  this.remaining -= Date.now() - this.startTime;
  this.isPaused = true;
  this.isTicking = false;
  return this.remaining;
};

/**
 * Sets time remaining to initial delay and clears timer.
 */
Timer.prototype.reset = function() {
  this.remaining = this.delay;
  this.clear();
};

/**
 * Resets the timer to the original delay, clears the current timer, and
 * starts the timer again.
 */
Timer.prototype.restart = function() {
  this.reset();
  this.resume();
};

/**
 * Clears timer.
 */
Timer.prototype.clear = function() {
  clearTimeout(this.timerId);
  this.isPaused = false;
  this.isTicking = false;
};

/**
 * Destroy the timer.
 */
Timer.prototype.dispose = function() {
  this.clear();
  this.fn = null;
};

module.exports = Timer;
