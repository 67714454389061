// Spark Device Enum
// =================
//
// An enumeration of device-specific settings for JS and CSS.
//
// * Author: Nurun

'use strict';

var Modernizr = window.Modernizr;

/**
 * Hyphenates a javascript style string to a css one. For example:
 * MozBoxSizing -> -moz-box-sizing.
 *
 * @param {string|boolean} str The string to hyphenate.
 * @return {string} The hyphenated string.
 */
var hyphenate = function(str) {

  // Catch booleans.
  if (!str) {
    return '';
  }

  // Turn MozBoxSizing into -moz-box-sizing.
  return str.replace(/([A-Z])/g, function(str, m1) {
    return '-' + m1.toLowerCase();
  }).replace(/^ms-/, '-ms-');
};

var DeviceEnum = {};

/**
 * Prefixed style properties.
 * @enum {string|boolean}
 */
DeviceEnum.Dom = {
  ANIMATION: Modernizr.prefixed('animation'),
  ANIMATION_DURATION: Modernizr.prefixed('animationDuration'),
  TRANSFORM: Modernizr.prefixed('transform'),
  TRANSITION: Modernizr.prefixed('transition'),
  TRANSITION_PROPERTY: Modernizr.prefixed('transitionProperty'),
  TRANSITION_DURATION: Modernizr.prefixed('transitionDuration'),
  TRANSITION_TIMING_FUNCTION: Modernizr.prefixed('transitionTimingFunction'),
  TRANSITION_DELAY: Modernizr.prefixed('transitionDelay')
};

/**
 * Prefixed css properties.
 * @enum {string}
 */
DeviceEnum.Css = {
  ANIMATION: hyphenate(DeviceEnum.Dom.ANIMATION),
  ANIMATION_DURATION: hyphenate(DeviceEnum.Dom.ANIMATION_DURATION),
  TRANSFORM: hyphenate(DeviceEnum.Dom.TRANSFORM),
  TRANSITION: hyphenate(DeviceEnum.Dom.TRANSITION),
  TRANSITION_PROPERTY: hyphenate(DeviceEnum.Dom.TRANSITION_PROPERTY),
  TRANSITION_DURATION: hyphenate(DeviceEnum.Dom.TRANSITION_DURATION),
  TRANSITION_TIMING_FUNCTION: hyphenate(DeviceEnum.Dom.TRANSITION_TIMING_FUNCTION),
  TRANSITION_DELAY: hyphenate(DeviceEnum.Dom.TRANSITION_DELAY)
};

/**
 * Whether the browser has css transitions.
 * @type {boolean}
 */
DeviceEnum.HAS_TRANSITIONS = Modernizr.csstransitions;

/**
 * Whether the browser has css animations.
 * @type {boolean}
 */
DeviceEnum.HAS_CSS_ANIMATIONS = Modernizr.cssanimations;

/**
 * Whether the browser has css transitions.
 * @type {boolean}
 */
DeviceEnum.HAS_TRANSFORMS = Modernizr.csstransforms;

/**
 * The browser can use css transitions and transforms.
 * @type {boolean}
 */
DeviceEnum.CAN_TRANSITION_TRANSFORMS = DeviceEnum.HAS_TRANSITIONS &&
  DeviceEnum.HAS_TRANSFORMS;

/**
 * The browser can use 3d css transforms.
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/transforms3d.js
 * @type {boolean}
 */
DeviceEnum.HAS_3D_TRANSFORMS = Modernizr.csstransforms3d;

/**
 * Whether the browser supports touch events.
 * @type {boolean}
 */
DeviceEnum.HAS_TOUCH_EVENTS = ('ontouchstart' in window) || !!window.DocumentTouch && document instanceof window.DocumentTouch;

DeviceEnum._HAS_UNPREFIXED_POINTER_EVENTS = !!navigator.pointerEnabled;

/**
 * Whether the browser supports pointer events.
 * http://blogs.windows.com/windows_phone/b/wpdev/archive/2012/11/15/adapting-your-webkit-optimized-site-for-internet-explorer-10.aspx
 * @type {boolean}
 */
DeviceEnum.HAS_POINTER_EVENTS = DeviceEnum._HAS_UNPREFIXED_POINTER_EVENTS || !!navigator.msPointerEnabled;

module.exports = DeviceEnum;
