'use strict';

var browser = {};

/**
 * Detects the native Android Operating System.
 *
 * @param {string} userAgent  The user agent string.
 * @return {boolean}
 */
browser.isAndroidOS = function(userAgent) {
  return userAgent.indexOf('Mozilla/5.0') > -1 && userAgent.indexOf('Android ') > -1;
};

/**
 * Detects the iOS operating system.
 *
 * @param {string} userAgent  The user agent string.
 * @return {boolean}
 */
browser.isIOS = function(userAgent) {
  return /(iPad|iPhone|iPod)/g.test(userAgent);
};

/**
 * User agent test for IOS. Determines whether current version is < 8. Version 8
 * and higher allow javascript execution while scrolling.
 *
 * @param {string} userAgent The user agent string.
 * @return {boolean}
 */
browser.hasScrollEvents = function(userAgent) {
  if (this.isIOS(userAgent)) {
    return this.getIOSVersion(userAgent) >= 800;
  } else {
    return !browser.isNativeAndroid(userAgent);
  }
};

/**
 * Detects the version of iOS operating system.
 *
 * @param {string} userAgent  The user agent string.
 * @return {number} iOS version. iOS 8.4.0, for example, will return `840`.
 */
browser.getIOSVersion = function(userAgent) {
  var iosUserAgent = userAgent.match(/OS\s+([\d\_]+)/i);
  var iosVersion = iosUserAgent[1].split('_');

  // The iOS ua string doesn't include the patch version if it's zero.
  if (iosVersion.length === 2) {
    iosVersion[2] = 0;
  }

  return parseInt(iosVersion.reduce(function(str, number) {
    return str + number;
  }, ''), 10);
};

/**
 * Detects all Google Chrome browsers.
 *
 * @param {string} userAgent  The user agent string.
 * @return {boolean}
 */
browser.isChrome = function(userAgent) {
  return userAgent.indexOf('Chrome') > -1;
};

/**
 * Whether the give user agent is from the stock Android browser.
 * @param {string} userAgent User agent string.
 * @return {boolean}
 */
browser.isNativeAndroid = function(userAgent) {
  return browser.isAndroidOS(userAgent) && !browser.isChrome(userAgent);
};

module.exports = browser;
