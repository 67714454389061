'use strict';

var DeviceEnum = window.SparkDeviceEnum || /* istanbul ignore next */ require('spark-device-enum');

var Utilities = require('./utilities');

module.exports = {

  /**
   * Returns the prefixed or unprefixed pointer event name or null if not pointer events.
   * @param {string} event The camel cased event name. e.g. "PointerDown".
   * @return {?string} The normalized event name or null.
   */
  getPointerEvent: function(event) {
    if (DeviceEnum.HAS_POINTER_EVENTS) {
      return DeviceEnum._HAS_UNPREFIXED_POINTER_EVENTS ?
        event.toLowerCase() :
        'MS' + event;
    }

    return null;
  },

  /**
   * Returns a normalized transition end event name.
   * @return {string}
   */
  getTransitionEndEvent: function() {
    // https://github.com/Modernizr/Modernizr/blob/master/src/prefixed.js
    return this.patchAndroidTransitionEndEvent({
      // Saf 6, Android Browser
      WebkitTransition: 'webkitTransitionEnd',

      // only for FF < 15
      MozTransition: 'transitionend',

      // IE10, Opera, Chrome, FF 15+, Saf 7+
      transition: 'transitionend'
    }[DeviceEnum.Dom.TRANSITION]);
  },

  /**
   * Issue with Modernizr prefixing related to stock Android 4.1.2
   * That version of Android has both unprefixed and prefixed transitions
   * built in, but will only listen to the prefixed on in certain cases
   * https://github.com/Modernizr/Modernizr/issues/897
   *
   * @param {string} transitionend The current transition end event name.
   * @return {string} A patched transition end event name.
   */
  patchAndroidTransitionEndEvent: function(transitionend) {
    var div = document.createElement('div');
    div.style.transition = 'width 2s';

    // Test the value which was just set. If it wasn't able to be set,
    // then it shouldn't use unprefixed transitions.
    if (div.style.transition !== 'width 2s' && 'webkitTransition' in div.style) {
      return 'webkitTransitionEnd';
    }

    return transitionend;
  },

  /**
   * Returns a normalized transition end event name.
   * @return {string}
   */
  getAnimationEndEvent: function() {
    return {
      WebkitAnimation: 'webkitAnimationEnd',
      animation: 'animationend'
    }[DeviceEnum.Dom.ANIMATION];
  },

  /**
   * Returns a string to be used with transforms. Uses 3d translates
   * when available.
   * @param {string=} x The x position value with units. Default is zero.
   * @param {string=} y The y position value with units. Default is zero.
   * @return {string} The css value for transform.
   */
  getTranslate: function(x, y) {
    x = Utilities.defaultsTo(x, 0);
    y = Utilities.defaultsTo(y, 0);

    var prefix = 'translate';

    if (DeviceEnum.HAS_3D_TRANSFORMS) {
      prefix += '3d';
      y += ',0';
    }

    return prefix + '(' + x + ',' + y + ')';
  }
};
