'use strict';

var array = {};

/**
 * Given an array of numbers (`arr`), find the item in the array closest
 * to a given number (`num`).
 *
 * @param  {Array.<number>} arr An array of numbers.
 * @param  {number} num Close number to search from.
 * @return {?number} The closest number in the array.
 */
array.closest = function(arr, num) {

  var closest = null;

  arr.reduce(function(closestDiff, value) {
    var diff = Math.abs(value - num);
    if (diff < closestDiff) {
      closest = value;
      return diff;
    } else {
      return closestDiff;
    }
  }, Infinity);

  return closest;
};

/**
 * Given an array of numbers (`arr`), find the item in the array closest
 * to a given number (`num`), while also less than (`num`).
 *
 * @param  {Array.<number>} arr An array of numbers.
 * @param  {number} num Close number to search from.
 * @return {?number} The closest number in the array.
 */
array.closestLessThan = function(arr, num) {

  var filtered = arr.filter(function(element) {
    return element < num;
  });

  return array.closest(filtered, num);
};

/**
 * Given an array of numbers (`arr`), find the item in the array closest
 * to a given number (`num`), while also greater than (`num`).
 *
 * @param  {Array.<number>} arr An array of numbers.
 * @param  {number} num Close number to search from.
 * @return {?number} The closest number in the array.
 */
array.closestGreaterThan = function(arr, num) {

  var filtered = arr.filter(function(element) {
    return element > num;
  });

  return array.closest(filtered, num);
};

/**
 * Make an array of smaller arrays from an array.
 * @param {Array.<*>} array An array to take chunks from.
 * @param {number} size The number of items per chunk.
 * @return {Array.<Array.<*>>}
 */
array.chunk = function(array, size) {
  if (!size) {
    return [];
  }

  var numArrays = Math.ceil(array.length / size);
  var chunked = new Array(numArrays);
  var i = 0;
  var index = 0;
  for (; i < numArrays; index += size, i++) {
    chunked[i] = array.slice(index, index + size);
  }

  return chunked;
};

/**
 * Finds and returns the longest word in an array of words.
 *
 * @param {Array.<string>} stringsArray An array containing individual strings.
 * @return {string} The longest word in the array.
 */
array.getLongestString = function(stringsArray) {
  var currentLongestIndex = 0;

  for (var i = 1; i < stringsArray.length; i++) {
    if (stringsArray[i].length > stringsArray[currentLongestIndex].length) {
      currentLongestIndex = i;
    }
  }

  return stringsArray[currentLongestIndex];
};

/**
 * Convert an array-like object (arguments, NodeList) to a real array.
 * @param {Object} arrayLike An array-like object with numeric keys.
 * @return {Array}
 */
array.toArray = function(arrayLike) {
  if ('from' in Array) {
    return Array.from(arrayLike);
  } else {
    return Array.prototype.slice.call(arrayLike);
  }
};

module.exports = array;
