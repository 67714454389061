'use strict';

var Utilities = require('./utilities');

/**
 * Class for representing coordinates and positions.
 * @param {number=} optX Left, defaults to 0.
 * @param {number=} optY Top, defaults to 0.
 * @constructor
 */
var Coordinate = function(optX, optY) {
  /**
   * X-value
   * @type {number}
   */
  this.x = optX !== undefined ? optX : 0;

  /**
   * Y-value
   * @type {number}
   */
  this.y = optY !== undefined ? optY : 0;
};

/**
 * Compares coordinates for equality.
 * @param {Coordinate} a A Coordinate.
 * @param {Coordinate} b A Coordinate.
 * @return {boolean} True iff the coordinates are equal, or if both are null.
 */
Coordinate.equals = function(a, b) {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  return a.x === b.x && a.y === b.y;
};

/**
 * Returns the distance between two coordinates.
 * @param {!Coordinate} a A Coordinate.
 * @param {!Coordinate} b A Coordinate.
 * @return {number} The distance between {@code a} and {@code b}.
 */
Coordinate.distance = function(a, b) {
  var dx = a.x - b.x;
  var dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
};

/**
 * Returns the difference between two coordinates as a new Coordinate.
 * @param {!Coordinate} a A Coordinate.
 * @param {!Coordinate} b A Coordinate.
 * @return {!Coordinate} A Coordinate representing the difference
 *     between {@code a} and {@code b}.
 */
Coordinate.difference = function(a, b) {
  return new Coordinate(a.x - b.x, a.y - b.y);
};

/**
 * Returns the sum of two coordinates as a new Coordinate.
 * @param {!Coordinate} a A Coordinate.
 * @param {!Coordinate} b A Coordinate.
 * @return {!Coordinate} A Coordinate representing the sum of the two coordinates.
 */
Coordinate.sum = function(a, b) {
  return new Coordinate(a.x + b.x, a.y + b.y);
};

/**
 * Returns the product of two coordinates as a new Coordinate.
 * @param {!Coordinate} a A Coordinate.
 * @param {!Coordinate} b A Coordinate.
 * @return {!Coordinate} A Coordinate representing the product of the two coordinates.
 */
Coordinate.product = function(a, b) {
  return new Coordinate(a.x * b.x, a.y * b.y);
};

/**
 * Returns the quotient of two coordinates as a new Coordinate.
 * @param {!Coordinate} a A Coordinate.
 * @param {!Coordinate} b A Coordinate.
 * @return {!Coordinate} A Coordinate representing the quotient of the two coordinates.
 */
Coordinate.quotient = function(a, b) {
  return new Coordinate(a.x / b.x, a.y / b.y);
};

/**
 * Scales this coordinate by the given scale factors. This does not affect the
 * properites of the coordinate parameter.
 * @param {!Coordinate} a A Coordinate.
 * @param {number} sx The scale factor to use for the x dimension.
 * @param {number=} optSy The scale factor to use for the y dimension.
 * @return {!Coordinate} This coordinate after scaling.
 */
Coordinate.scale = function(a, sx, optSy) {
  return new Coordinate(a.x * sx, a.y * Utilities.defaultsTo(optSy, sx));
};

/**
 * Returns a duplicate of this coordinate.
 * @return {Coordinate}
 */
Coordinate.prototype.clone = function() {
  return new Coordinate(this.x, this.y);
};

/**
 * Scales this coordinate by the given scale factors. The x and y values are
 * scaled by {@code sx} and {@code optSy} respectively.  If {@code optSy}
 * is not given, then {@code sx} is used for both x and y.
 * @param {number} sx The scale factor to use for the x dimension.
 * @param {number=} optSy The scale factor to use for the y dimension.
 * @return {!Coordinate} This coordinate after scaling.
 */
Coordinate.prototype.scale = function(sx, optSy) {
  this.x *= sx;
  this.y *= Utilities.defaultsTo(optSy, sx);
  return this;
};

/**
 * Translates this box by the given offsets. If a {@code Coordinate}
 * is given, then the x and y values are translated by the coordinate's x and y.
 * Otherwise, x and y are translated by {@code tx} and {@code opt_ty}
 * respectively.
 * @param {number|Coordinate} tx The value to translate x by or the
 *     the coordinate to translate this coordinate by.
 * @param {number} ty The value to translate y by.
 * @return {!Coordinate} This coordinate after translating.
 */
Coordinate.prototype.translate = function(tx, ty) {
  if (tx instanceof Coordinate) {
    this.x += tx.x;
    this.y += tx.y;
  } else {
    this.x += tx;
    this.y += ty;
  }

  return this;
};

module.exports = Coordinate;
