'use strict';

var $ = window.jQuery || /* istanbul ignore next */ require('jquery');
var DeviceEnum = window.SparkDeviceEnum || /* istanbul ignore next */ require('spark-device-enum');

var Stepper = require('./animation-stepper');
var Utilities = require('./utilities');
var Events = require('./enums/events');

var animation = {};

animation.Classes = {
  FADE: 'fade',
  IN: 'in',
  INVISIBLE: 'invisible'
};

animation.Stepper = Stepper;

animation.scrollTo = function(position, speed, easing, callback) {
  // Only call the callback one time. It could fire twice because jQuery
  // will animate both the html and body elements (for legacy browsers,
  // it should be only the body element, but we cannot do that yet).
  // Using jQuery's deferred object (promise), the callback only executes once.
  $('html,body').animate({
    scrollTop: position
  }, speed, easing).promise().done(Utilities.defaultsTo(callback, $.noop));
};

animation.scrollToTop = function() {
  animation.scrollTo(0, 400, 'swing', null);
};

/**
 * Fade in an element and optionally add a class which sets visibility
 * to hidden.
 * @param {Element} elem Element to fade.
 * @param {Function=} optFn Callback function when faded out.
 * @param {Object=} optThis Context for the callback.
 * @param {boolean=} optInvisible Whether to add visibility:hidden to the
 *     element once it has faded. Defaults to false.
 */
animation.fadeOutElement = function(elem, optFn, optThis, optInvisible) {
  return animation.fadeElement(elem, optFn, optThis, optInvisible, true);
};

/**
 * Fade in an element and optionally remove a class which sets visibility
 * to hidden.
 * @param {Element} elem Element to fade.
 * @param {Function=} optFn Callback function when faded out.
 * @param {Object=} optThis Context for the callback.
 * @param {boolean=} optInvisible Whether to add visibility:hidden to the
 *     element once it has faded. Defaults to false.
 */
animation.fadeInElement = function(elem, optFn, optThis, optInvisible) {
  return animation.fadeElement(elem, optFn, optThis, optInvisible, false);
};

/**
 * Fade out an element and then set visibilty hidden on it.
 * @param {Element} elem Element to fade.
 * @param {Function=} optFn Callback function when faded out.
 * @param {Object=} optThis Context for the callback.
 * @param {boolean=} optInvisible Whether to add visibility:hidden to the
 *     element once it has faded out. Defaults to false.
 * @param {boolean=} optIsOut Whether to fade out or in. Defaults to fade out.
 * @return {number} id used to cancel the transition end listener.
 */
animation.fadeElement = function(elem, optFn, optThis, optInvisible, optIsOut) {
  elem = animation._getElement(elem);

  // Bind the context to the callback here so that the context and function
  // references can be garbage collected and the only things left are `callback`
  // and `optInvisible`.
  var fn = $.isFunction(optFn) ? optFn : $.noop;
  var callback = $.proxy(fn, Utilities.defaultsTo(optThis, window));
  var isOut = !!optIsOut;

  // Make sure the transition will actually happen.
  // isIn and has `in` and `fade` classes or
  // isIn but doesn't have `fade` or
  // isOut and has `fade`, but doesn't have `in` class.
  if ((!isOut && $(elem).hasClass(animation.Classes.IN) && $(elem).hasClass(animation.Classes.FADE)) ||
      (!isOut && !$(elem).hasClass(animation.Classes.FADE)) ||
      (isOut && !$(elem).hasClass(animation.Classes.IN) && $(elem).hasClass(animation.Classes.FADE))) {

    var fakeEvent = animation._getFakeEvent(elem);

    // This is expected to be async.
    setTimeout(function() {
      callback(fakeEvent);
    }, 0);

    return;
  }

  /**
   * Internal callback when the element has finished its transition.
   * @param {$.Event|{target: Element, currentTarget: Element}}
   *     evt Event object.
   */
  function faded(evt) {
    // Element has faded out, add invisible class.
    if (isOut && optInvisible) {
      $(evt.currentTarget).addClass(animation.Classes.INVISIBLE);
    }

    callback(evt);
  }

  // Fading in, remove invisible class.
  if (!isOut && optInvisible) {
    $(elem).removeClass(animation.Classes.INVISIBLE);
  }

  // Make sure it has the "fade" class. It won't do anything if it already does.
  $(elem).addClass(animation.Classes.FADE);

  // Remove (or add) the "in" class which triggers the transition.
  // If the element had neither of these classes, adding the "fade" class
  // will trigger the transition.
  $(elem).toggleClass(animation.Classes.IN, !isOut);

  return animation.onTransitionEnd(elem, faded, null, 'opacity');
};

/**
 * Returns the element when the first parameter is a jQuery collection.
 * @param {Element|jQuery} elem An element or a jQuery collection.
 * @return {Element}
 * @throws {Error} If it's a jQuery collection of more than one element.
 */
animation._getElement = function(elem) {
  if (elem.jquery) {
    if (elem.length > 1) {
      throw new TypeError('This method only supports transition end for one element, not a collection');
    }

    elem = elem[0];
  }

  return elem;
};

animation._isOwnEvent = function(event) {
  return event.target === event.currentTarget;
};

animation._isDefined = function(value) {
  return value !== undefined && value !== null;
};

animation._isSameTransitionProperty = function(event, prop) {
  return event.fake || !animation._isDefined(prop) || event.originalEvent.propertyName === prop;
};

animation._getFakeEvent = function(elem) {
  return {
    target: elem,
    currentTarget: elem,
    fake: true
  };
};

animation._transitions = {};
animation._transitionId = 0;

animation.onTransitionEnd = function(elem, fn, context, optProperty, optTimeout) {
  elem = animation._getElement(elem);

  var callback = $.proxy(fn, Utilities.defaultsTo(context, window));
  var transitionId = animation._transitionId++;
  var timerId;

  /**
   * @param {$.Event|{target: Element, currentTarget: Element}} evt Event object.
   */

  function transitionEnded(evt) {
    // Some other element's transition event could have bubbled up to this.
    // or
    // If the optional property exists and it's not the property which was
    // transitioned, exit out of the function and continue waiting for the
    // right transition property.
    if (!animation._isOwnEvent(evt) || !animation._isSameTransitionProperty(evt, optProperty)) {
      return;
    }

    // Remove from active transitions.
    delete animation._transitions[transitionId];

    // If the browser has transitions, there will be a listener bound to the
    // `transitionend` event which needs to be removed. `jQuery().one` is not used
    // because transition events can bubble up to the parent.
    if (DeviceEnum.HAS_TRANSITIONS) {
      $(evt.currentTarget).off(Events.TRANSITIONEND, transitionEnded);
    }

    // Done!
    callback(evt);
    clearTimeout(timerId);
  }

  if (DeviceEnum.HAS_TRANSITIONS) {
    $(elem).on(Events.TRANSITIONEND, transitionEnded);

    // Sometimes the transition end event doesn't fire, usually when
    // properties don't change or when iOS decides to just snap instead of
    // transition. To get around this, a timer is set which will trigger the
    // fake event.
    if (optTimeout) {
      timerId = setTimeout(function fallbackTimer() {
        transitionEnded(animation._getFakeEvent(elem));
      }, optTimeout);
    }

  } else {

    // Push to the end of the queue with a fake event which will pass the checks
    // inside the callback function.
    timerId = setTimeout(function() {
      transitionEnded(animation._getFakeEvent(elem));
    }, 0);
  }

  // Save this active transition end listener so it can be canceled.
  animation._transitions[transitionId] = {
    element: elem,
    timerId: timerId,
    listener: transitionEnded
  };

  // Return id used to cancel the transition end listener, similar to setTimeout
  // and requestAnimationFrame.
  return transitionId;
};

/**
 * Remove the event listener for `transitionend`.
 * @param {number} id The number returned by `onTransitionEnd`.
 * @return {boolean} Whether the transition was canceled or not. If the transition
 *     already finished, this method will return false.
 */
animation.cancelTransitionEnd = function(id) {
  var obj = this._transitions[id];

  if (obj) {
    clearTimeout(obj.timerId);

    if (DeviceEnum.HAS_TRANSITIONS) {
      $(obj.element).off(Events.TRANSITIONEND, obj.listener);
    }

    delete this._transitions[id];
    return true;

  } else {
    return false;
  }
};

/**
 * Execute a callback when a css animation finishes.
 * @param {Element|jQuery} elem The element which as an animation on it.
 * @param {Function} fn Callback function
 * @param {Object=} context Optional context for the callback.
 */
animation.onAnimationEnd = function(elem, fn, context) {
  elem = animation._getElement(elem);

  var callback = $.proxy(fn, Utilities.defaultsTo(context, window));

  function animationEnded(evt) {
    // Ensure the `animationend` event was from the element specified.
    if (!animation._isOwnEvent(evt)) {
      return;
    }

    // Remove the listener if it was bound.
    if (DeviceEnum.HAS_CSS_ANIMATIONS) {
      $(evt.currentTarget).off(Events.ANIMATIONEND, animationEnded);
    }

    callback();
  }

  if (DeviceEnum.HAS_CSS_ANIMATIONS) {
    $(elem).on(Events.ANIMATIONEND, animationEnded);

  } else {

    // Callback is expected to be async, so push it to the end of the queue.
    setTimeout(function() {
      animationEnded(animation._getFakeEvent(elem));
    }, 0);
  }
};

module.exports = animation;
