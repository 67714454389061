/**
 * @fileoveriew Callouts list with big numbers, which transforms to a carousel on mobile
 *
 * @author Christopher J Mischler
 */

'use strict';

var $ = require('jquery');
var enquire = require('enquire');
var sparkDeviceEnum = require('spark-device-enum');
var sparkHelpers = require('spark-helpers');
var sparkDraggable = require('spark-draggable');
var SparkCarousel = require('spark-carousel');

/**
 * Callouts list with big numbers, which transforms to a carousel on mobile
 * @param {Element} element Main element.
 *
 * @constructor
 */
var CalloutsWBigNumberList = function(element) {
  /**
   * Base Element
   * @type {Element}
   * @private
   */
  this.element = element;

  /** @type {jQuery} */
  this.$element = $(element);

  this._init();
};

CalloutsWBigNumberList.prototype._init = function() {
  var _this = this;

  enquire.register("screen and (max-width:719px)", {

    match : function() {
      _this._initMobileCarousel();
    },

    unmatch : function() {
      _this._destroyMobileCarousel();
    }
  });
};

CalloutsWBigNumberList.prototype._initMobileCarousel = function() {
  this._sparkCarousel = new SparkCarousel(document.getElementById('callouts-w-big-number-list-carousel'), {
    template: { paddles: '' }
  });
};

CalloutsWBigNumberList.prototype._destroyMobileCarousel = function() {
  if (this._sparkCarousel) {
    this._sparkCarousel.dispose();
  }
};

module.exports = {
  init: function() {
    var $module = $('.callouts-w-big-number-list');

    $module.each(function() {
      new CalloutsWBigNumberList(this);
    });
  }
};
