var SparkPointer = window.SparkPointer || /* istanbul ignore next */ require('spark-pointer');

module.exports = {
  /** @enum {string} */
  EventType: {
    START: 'draggablestart',
    MOVE: 'draggablemove',
    END: 'draggableend',
    SETTLE: 'draggablethrowsettle'
  },

  defaults: {
    // Draggable axis.
    axis: SparkPointer.settings.Axis.X,

    // Ampflifies throw velocity by this value. Higher values make the throwable
    // travel farther and faster.
    amplifier: 24,

    // Once the velocity has gone below this threshold, throwing stops.
    velocityStop: 0.08,

    // On each throw frame, the velocity is multiplied by this friction value.
    // It must be less than 1. Higher values let the throwable slide farther and longer.
    throwFriction: 0.94,

    // Whether the draggable will keep its movement momentum after the user releases.
    isThrowable: false
  }
};
